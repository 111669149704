import gsap from 'gsap'
import SplitText from 'gsap/SplitText'

gsap.registerPlugin(SplitText)

import Animation from 'classes/Animation'

export default class Text extends Animation {
    constructor(props) {
        super(props)
    }

    init() {
        super.init()

        this.split = new SplitText(this.el, {
            type: 'lines',
            linesClass: 'line line++'
        })

        this.lines = this.split.lines

        this.lines.forEach((line, i) => {
            const lines = new SplitText(line, {
                type: 'lines',
                linesClass: 'line-inner'
            })

            const lineInner = lines.lines[0]

            gsap.set(line, {
                overflow: 'hidden',
                verticalAlign: 'top',
                paddingBottom: '0.2rem',
                marginTop: '-0.2rem'
            })

            gsap.set(lineInner, {
                yPercent: 120,
                left: '0',
            })

            this.tl.to(lineInner, {
                yPercent: 0,
                ease: 'Power4.easeOut',
                duration: 1.2,
                delay: i / 10,
            }, '0')
        })
    }

    play() {
        super.play()
    }

    onComplete() {
        super.onComplete()
    }

    destroy() {
        super.destroy()
    }
}