import Component from 'classes/Component'

export default class Select extends Component {
    constructor(element) {
        super()

        this.el = element
        this.parent = this.el.parentNode
    }

    init() {
        super.init()

        this.options = []
        this.optionEls = this.el.querySelectorAll('option')
        this.placeholderText = this.optionEls[0].textContent

        this.wrapper = document.createElement('span')
        this.label = document.createElement('span')
        this.labelInner = document.createElement('span')
        this.dropdown = document.createElement('span')

        this.wrapper.classList.add('select-wrapper')
        this.label.classList.add('select-label')
        this.labelInner.classList.add('select-label-inner')
        this.dropdown.classList.add('select-dropdown')

        this.labelInner.textContent = this.placeholderText

        this.parent.insertBefore(this.wrapper, this.el)
        this.wrapper.appendChild(this.label)
        this.label.appendChild(this.labelInner)
        this.wrapper.appendChild(this.dropdown)
        this.wrapper.appendChild(this.el)

        document.addEventListener('click', this.onDocumentClick.bind(this))
        this.label.addEventListener('click', this.toggle.bind(this))

        this.optionEls.forEach((el, i) => {
            const optionEl = document.createElement('span')
            optionEl.classList.add('select-option')
            optionEl.innerHTML = el.textContent
            optionEl.setAttribute('data-value', el.textContent)
            optionEl.setAttribute('data-index', i)

            this.dropdown.appendChild(optionEl)
            optionEl.addEventListener('click', this.onOptionClick.bind(this))

            this.options.push({
                el: optionEl,
                index: i,
                value: el.textContent
            })
        })
    }

    onDocumentClick(e) {
        const target = e.target

        if (!this.wrapper.contains(target)) {
            this.hide()
        }
    }

    toggle() {
        this.selects = document.querySelectorAll('.select-wrapper')

        if (this.wrapper.classList.contains('active')) {
            this.hide()
        } else {
            this.show()
        }

        this.selects.forEach(select => {
            if (select !== this.wrapper) {
                select.classList.remove('active')
            }
        })
    }

    show() {
        this.wrapper.classList.add('active')
    }

    hide() {
        this.wrapper.classList.remove('active')
    }

    onOptionClick(e) {
        const option = e.target
        const changeEvent = new Event('change')

        this.el.value = option.getAttribute('data-value')
        this.labelInner.innerHTML = option.textContent
        this.el.dispatchEvent(changeEvent)
        this.hide()
    }

    pageLoaded() {
        super.pageLoaded()
    }

    update() {
        super.update()
    }

    resize() {
        super.resize()
    }

    destroy() {
        super.destroy()

        document.removeEventListener('click', this.onDocumentClick.bind(this))
        this.label.removeEventListener('click', this.toggle.bind(this))

        this.options.forEach(option => {
            option.el.removeEventListener('click', this.onOptionClick.bind(this))
        })
    }
}