export default {
    // Elements

    'Accordion': {
        element: null,
        folder: 'elements'
    },
    'Button': {
        element: null,
        folder: 'elements'
    },
    'Dropdown': {
        element: null,
        folder: 'elements'
    },
    'Form': {
        element: 'wpcf7-form',
        folder: 'elements'
    },
    'Modal': {
        element: null,
        folder: 'elements'
    },
    'Select': {
        element: null,
        folder: 'elements'
    },
    'Tooltip': {
        element: null,
        folder: 'elements'
    },

    // Components

    'ArrowGrid': {
        element: 'arrow-grid',
        folder: null
    },
    'AppFab': {
        element: null,
        folder: null
    },
    'CardScroller': {
        element: 'card-scroller',
        folder: null
    },
    'Carousel': {
        element: 'carousel',
        folder: null
    },
    'Categories': {
        element: null,
        folder: null
    },
    'Chatbot': {
      element: null,
      folder: null
    },
    'Channels': {
        element: null,
        folder: null
    },
    'ContentGridOne': {
        element: 'content-grid-one',
        folder: null
    },
    'ContentGridTwo': {
        element: 'content-grid-two',
        folder: null
    },
    'ContentGridThree': {
        element: 'content-grid-three',
        folder: null
    },
    'ContentGridFour': {
        element: 'content-grid-four',
        folder: null
    },
    'ContentGridFive': {
        element: 'content-grid-five',
        folder: null
    },
    'CTA': {
        element: 'cta',
        folder: null
    },
    'Download': {
        element: null,
        folder: null
    },
    'FeaturedDeals': {
        element: null,
        folder: null
    },
    'FeaturedStores': {
        element: null,
        folder: null
    },
    'Hero': {
        element: null,
        folder: null
    },
    'HeroGrid': {
        element: null,
        folder: null
    },
    'ImageStack': {
        element: 'image-stack',
        folder: null
    },
    'Industries': {
        element: null,
        folder: null
    },
    'RelatedStores': {
        element: null,
        folder: null
    },
    'Resources': {
        element: null,
        folder: null
    },
    'Slider': {
        element: null,
        folder: null
    },
    'Solutions': {
        element: null,
        folder: null
    },
    'Statistics': {
        element: null,
        folder: null
    },
    'TestimonialSlider': {
        element: null,
        folder: null
    },
}