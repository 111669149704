import gsap from 'gsap'
import SplitText from 'gsap/SplitText'

gsap.registerPlugin(SplitText)

import Animation from 'classes/Animation'

export default class Title extends Animation {
    constructor(props) {
        super(props)
    }

    init() {
        super.init()

        this.split = new SplitText(this.el, {
            type: 'words',
            wordsClass: 'word word++'
        })

        this.words = this.split.words

        this.words.forEach((word, i) => {
            const words = new SplitText(word, {
                type: 'words',
                wordsClass: 'word-inner'
            })

            const wordInner = words.words[0]

            gsap.set(word, {
                overflow: 'hidden',
                verticalAlign: 'top',
            })

            gsap.set(wordInner, {
                yPercent: 120,
                left: '0',
            })

            this.tl.to(wordInner, {
                yPercent: 0,
                ease: 'Power4.easeOut',
                duration: 1.6,
                delay: i / 20,
            }, '0')
        })
    }

    play() {
        super.play()
    }

    onComplete() {
        super.onComplete()
    }

    destroy() {
        super.destroy()
    }
}