import gsap from 'gsap'

export default class Animation {
    constructor(props) {

        if (!'el' in props) {
            return
        }

        this.el = props.el
        this.props = props.props ?? {}

        this.defaultProps = {
            paused: true,
            onComplete: this.onComplete.bind(this)
        }

        this.tl = gsap.timeline({
            ...this.defaultProps,
            ...this.props,
        })

        this.init()
    }

    init() {

    }

    play() {
        this.tl.play()
    }

    onComplete() {

    }

    destroy() {
        this.tl.kill()
    }
}