import gsap from 'gsap'
import Animation from 'classes/Animation'

export default class Clip extends Animation {
    constructor(props) {
        super(props)
    }

    init() {
        super.init()

        this.tl.fromTo(this.el, {
            yPercent: -5,
            clipPath: 'inset(0% 0% 100% 0%)'
        }, {
            yPercent: 0,
            clipPath: 'inset(0% 0% 0% 0%)',
            duration: 1.2,
            ease: 'Power4.easeInOut'
        })
    }

    play() {
        super.play()
    }

    onComplete() {
        super.onComplete()

        gsap.set(this.el, {
            clearProps: 'all'
        })
    }

    destroy() {
        super.destroy()
    }
}