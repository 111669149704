import gsap from 'gsap'
import SplitText from 'gsap/SplitText'

gsap.registerPlugin(SplitText)

import Animation from 'classes/Animation'

export default class Chars extends Animation {
    constructor(props) {
        super(props)
    }

    init() {
        super.init()

        if (!this.el.classList.contains('anim-chars')) {
            this.el.classList.add('anim-chars')
        }

        this.split = new SplitText(this.el, {
            type: 'chars',
            charsClass: 'char char++'
        })

        this.chars = this.split.chars

        this.chars.forEach((char, i) => {
            const chars = new SplitText(char, {
                type: 'chars',
                charsClass: 'char-inner'
            })

            const charInner = chars.chars[0]

            gsap.set(char, {
                overflow: 'hidden',
                verticalAlign: 'top'
            })

            gsap.set(charInner, {
                yPercent: 120,
                left: '0',
            })

            this.tl.to(charInner, {
                yPercent: 0,
                ease: 'Power4.easeOut',
                duration: 1.2,
                delay: i / 10,
            }, '0')
        })
    }

    play() {
        super.play()
    }

    onComplete() {
        super.onComplete()
    }

    destroy() {
        super.destroy()
    }
}