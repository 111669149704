import Site from 'stores/Site'
import Components from 'stores/Components'
import Button from 'components/elements/Button'
import {splitLine} from 'utils/splitLine'
import Cookie from 'js-cookie'

export default class Page {
    constructor() {
        this.classes = []
        this.componentMap = Components
    }

    init() {
        this.initLazyImages()
        this.initButtons()
        this.initSplitLines()

        let pageType = 'business'

        if (Site.isShopper) {
            pageType = 'shopper'

            this.initComponent('Chatbot')
        } else {
            document.documentElement.classList.remove('header-cta-active')
        }

        Cookie.set('pjn_page_type', pageType)
    }

    async loadComponent(path, retryCount = 0) {
        try {
            const module = await import(`components/${path}`)
            const component = {
                instance: module.default
            }
            return component
        } catch (error) {
            console.warn(`Failed to load component at path:${path} \nError: ${error}`)

            if (retryCount < 1) {
                console.log('Retrying...')
                return await this.loadComponent(path, retryCount + 1)
            }

            throw error
        }
    }

    initComponent(id, sectionHash = null, data = {}) {
        return new Promise(async (resolve, reject) => {
            let path
            const folder = this.componentMap[id].folder

            if (folder) {
                path = folder + '/' + id
            } else {
                path = id
            }

            try {
                const component = await this.loadComponent(path)

                if (sectionHash) {
                    const section = Site.page.querySelector('.section-' + sectionHash)
                    const el = section.querySelector('.' + this.componentMap[id].element)

                    component.class = new component.instance(el, data)
                } else {
                    component.class = new component.instance(data)
                }

                component.class.init()
                this.classes.push(component.class)

                resolve()
            } catch(error) {
                //barba.go('/404')

                reject(error)
            }
        })
    }

    initLazyImages() {
        this.images = Site.page.querySelectorAll('.image.lazyload')

        this.images.forEach(image => {
            const parent = image.closest('.media-wrapper')
            parent.classList.add('lazy', 'unloaded')

            image.addEventListener('lazyloaded', function(e) {
                parent.classList.remove('unloaded')
                parent.classList.add('loaded')
            })
        })
    }

    initButtons() {
        this.buttonEls = Site.page.querySelectorAll('.btn')

        if (this.buttonEls.length === 0) return

        this.buttonEls.forEach(el => {
            const btn = new Button(el)
            this.classes.push(btn)

            requestAnimationFrame(() => {
                btn.init()
            })
        })
    }

    initSplitLines() {
        this.splitLineEls = Site.page.querySelectorAll('.split-line')

        if (this.splitLineEls.length === 0) return

        this.splitLineEls.forEach(el => {
            requestAnimationFrame(() => {
                splitLine(el)
            })
        })
    }

    handleCta() {
        if (!Site.isMobile) return

        this.cta = {
            el: document.documentElement.querySelector('.header-cta')
        }

        if (this.cta.el && this.cta.el.length === 0) {
            return
        }

        console.log('ran')

        if (Cookie.get('pjn_hide_cta')) {
            this.cta.el.remove()
            Site.resize()

            return
        }
        document.documentElement.classList.add('header-cta-active')
        this.cta.close = this.cta.el.querySelector('.header-cta-close')
        this.cta.close.addEventListener('click', this.onCtaClose.bind(this))
    }

    onCtaClose() {
        console.log('test')

        Cookie.set('pjn_hide_cta', 'true')
        document.documentElement.classList.remove('header-cta-active')
        this.cta.el.remove()
        Site.resize()
    }

    pageLoaded() {
        this.classes.forEach(instance => {
            if (instance.pageLoaded()) {
                instance.pageLoaded()
            }
        })

        if (Site.isShopper) {
            this.handleCta()
        }
    }

    update() {
        this.classes.forEach(instance => {
            if (instance.update()) {
                instance.update()
            }
        })
    }

    resize() {
        this.classes.forEach(instance => {
            if (instance.resize) {
                instance.resize()
            }
        })
    }

    destroy() {
        this.classes.forEach(instance => {
            if (instance.destroy) {
                instance.destroy()
            }
        })

        if (this.cta && this.cta.close) {
            this.cta.close.removeEventListener('click', this.onCtaClose.bind(this))
        }
    }
}