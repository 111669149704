import gsap from 'gsap'

import Component from 'classes/Component'

export default class ArrowGrid extends Component {
    constructor(element) {
        super()

        this.el = element
    }

    init() {
        super.init()

        this.tl = gsap.timeline({
            paused: true
        })

        this.top = this.el.querySelector('.ag-top')
        this.right = this.el.querySelector('.ag-right')

        if (this.top) {
            this.topItems = this.top.querySelectorAll(':scope > *')

            this.tl.fromTo(this.top, {
                xPercent: 120
            }, {
                xPercent: 0,
                ease: 'Power4.easeOut',
                duration: 2.2,
            }, 0)

            this.topItems.forEach((item, i) => {
                if (i !== 0) {
                    this.tl.fromTo(item, {
                        x: `${i * 20}rem`
                    }, {
                        x: 0,
                        ease: 'Power4.easeOut',
                        duration: 1.6 + i / 10,
                    }, 0)
                }
            })
        }

        if (this.right) {
            this.rightItems = this.right.querySelectorAll(':scope > *')

            this.tl.fromTo(this.right, {
                yPercent: 120
            }, {
                yPercent: 0,
                ease: 'Power4.easeOut',
                duration: 2,
            }, .2)

            this.rightItems.forEach((item, i) => {
                if (i !== 0) {
                    this.tl.fromTo(item, {
                        y: `${i * 20}rem`
                    }, {
                        y: 0,
                        ease: 'Power4.easeOut',
                        duration: 1 + i / 10,
                    }, .6)
                }
            })
        }
    }

    play() {
        this.tl.play()
    }

    pageLoaded() {
        super.pageLoaded()
    }

    update() {
        super.update()
    }

    resize() {
        super.resize()
    }

    destroy() {
        super.destroy()
    }
}