import Site from 'stores/Site'
import Component from 'classes/Component'

export default class Tooltip extends Component {
    constructor(element) {
        super()

        this.el = element
        this.icon = this.el.querySelector('.tooltip-icon')
        this.content = this.el.querySelector('.tooltip-content')
        this.btn = this.el.querySelector('.tooltip-btn')
    }

    init() {
        super.init()

        if (Site.isMobile) {
            this.touchstartY = 0
            this.touchendY = 0
            this.touchThreshold = 10

            this.icon.addEventListener('click', this.toggle.bind(this))
            this.content.addEventListener('touchstart', this.onTouchStart.bind(this))
            this.content.addEventListener('touchend', this.onTouchEnd.bind(this))
            this.btn.addEventListener('click', this.toggle.bind(this))
        }
    }

    toggle() {
        this.tooltips = document.querySelectorAll('.tooltip')

        if (this.el.classList.contains('active')) {
            this.el.classList.remove('active')
        } else {
            this.el.classList.add('active')
        }

        this.tooltips.forEach(tooltip => {
            if (tooltip !== this.el) {
                tooltip.classList.remove('active')
            }
        })
    }

    show() {
        this.el.classList.add('active')
    }

    hide() {
        this.el.classList.remove('active')
    }

    onTouchStart(e) {
        this.touchstartY = e.changedTouches[0].screenY
    }

    onTouchEnd(e) {
        this.touchendY = e.changedTouches[0].screenY

        if (this.touchendY > this.touchstartY && this.touchendY - this.touchstartY > this.touchThreshold) {
            this.hide()
        }
    }

    pageLoaded() {
        super.pageLoaded()
    }

    update() {
        super.update()
    }

    resize() {
        super.resize()
    }

    destroy() {
        super.destroy()

        if (Site.isMobile) {
            this.icon.removeEventListener('click', this.toggle.bind(this))
            this.content.removeEventListener('touchstart', this.onTouchStart.bind(this))
            this.content.removeEventListener('touchend', this.onTouchEnd.bind(this))
            this.btn.removeEventListener('click', this.toggle.bind(this))
        }
    }
}