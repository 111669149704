import gsap from 'gsap'

import Component from 'classes/Component'

export default class Parallax extends Component {
    constructor(element) {
        super()

        this.el = element

        this.init()
    }

    init() {
        super.init()

        this.inner = this.el.querySelector('.media-inner')
        this.media = this.el.querySelector('.media')
        this.tl = gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                start: 'top bottom',
                end: 'bottom top',
                scrub: 1
            }
        })

        gsap.set(this.el, {
            overflow: 'hidden'
        })

        gsap.set(this.inner, {
            width: '110%',
            height: '110%',
            scale: 1.1,
            yPercent: -10
        })

        this.tl.to(this.inner, {
            scale: 1,
            yPercent: 0
        })
    }

    pageLoaded() {
        super.pageLoaded()
    }

    update() {
        super.update()
    }

    resize() {
        super.resize()
    }

    destroy() {
        super.destroy()
    }
}